import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import TabbedContent from '../TabbedContent/TabbedContent';
import { DefaultProps, PropTypes } from '../../../core/Utils/SitecoreFields';

class HelpMeFind extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: null,
      isExpanded: false,
      savedTabIndex: 0,
    };
  }

  toggleDropdown = () => {
    this.setState((prevState) => {
      return {
        isExpanded: !prevState.isExpanded,
      };
    });
  };

  selectDropdown = (tab) => {
    //Force refresh of the tab object due to object spreader in the tab fields
    this.setState(
      {
        activeTab: null,
      },
      () =>
        this.setState({
          activeTab: tab,
          isExpanded: false,
        }),
    );
  };

  render() {
    const { title, placeholder, tabList, ariaLabel } = this.props.fields;
    const { activeTab, isExpanded, savedTabIndex } = this.state;

    return (
      <div className="tab-dropdown-container help-me-find">
        <div className="l-padding">
          <div className="animated pulse">
            <div className="tab-dropdown">
              <div className="dropdown-header  ">
                <Text field={title} tag="h2" />
              </div>
              <div
                className={`dropdown-body ${
                  isExpanded ? 'dropdown-body__open' : ''
                }`}
              >
                <button
                  id="helpMeFind"
                  type="button"
                  className="placeholder"
                  aria-expanded={isExpanded}
                  aria-label={ariaLabel.value}
                  onClick={this.toggleDropdown}
                >
                  {activeTab ? (
                    activeTab.fields.heading.value
                  ) : (
                    <Text
                      field={activeTab ? activeTab.fields.heading : placeholder}
                    />
                  )}
                </button>
              </div>
              {isExpanded && (
                <div
                  className="dropdown-list is-shown"
                  aria-labelledby="helpMeFind"
                >
                  <ul>
                    {tabList.map((item) => {
                      return (
                        <li key={item.fields.heading.value}>
                          <button
                            className="dropdown-list-button"
                            type="button"
                            onClick={() => this.selectDropdown(item)}
                          >
                            {item.fields.heading.value}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
          {activeTab && (
            <TabbedContent
              fields={{
                ...activeTab.fields,
                heading: {},
              }}
              savedTab={savedTabIndex}
              handleActiveIndex={(index) =>
                this.setState({ savedTabIndex: index })
              }
              noPadding
            />
          )}
        </div>
      </div>
    );
  }
}

HelpMeFind.propTypes = PropTypes.SitecoreItem.inject({
  title: PropTypes.SingleLineText,
  placeholder: PropTypes.SingleLineText,
  ariaLabel: PropTypes.SingleLineText,
  tabList: PropTypes.Multilist.inject({
    heading: PropTypes.SingleLineText,
    canHide: PropTypes.Checkbox,
    tabItems: PropTypes.Multilist.inject(PropTypes.ContentBlock),
    hideText: PropTypes.SingleLineText,
  }),
});

HelpMeFind.defaultProps = DefaultProps.SitecoreItem.inject({
  title: DefaultProps.SingleLineText,
  placeholder: DefaultProps.SingleLineText,
  tabList: DefaultProps.Multilist,
  ariaLabel: DefaultProps.SingleLineText,
});

export default HelpMeFind;
